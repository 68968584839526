(() => {
  const projectsHeader = document.querySelector('.projects h1');

  const arrow = document.querySelector('.arrow');

  // scroll to projects
  arrow.addEventListener('click', () => {
    window.scrollTo({
      behavior: 'smooth',
      left: 0,
      top:
        projectsHeader.getBoundingClientRect().top -
        window.getComputedStyle(projectsHeader).marginTop.slice(0, -2)
    });
  });

  const projectSections = document.querySelectorAll('.project__frame > div');
  projectSections.forEach(projectSection => {
    projectSection.addEventListener('click', () => {
      window.open(projectSection.dataset.link, '_blank');
    });
  });
})();
